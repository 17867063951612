// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {jhY07x2nQ: {hover: true}};

const cycleOrder = ["jhY07x2nQ", "EkLcw9KlX"];

const variantClassNames = {EkLcw9KlX: "framer-v-6ktg07", jhY07x2nQ: "framer-v-mz8a03"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "jhY07x2nQ", "Variant 2": "EkLcw9KlX"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "jhY07x2nQ", image: GNAGgtuGK = {src: new URL("assets/512/WpRBQtPsl40LtvHBOpsjGdjrM0.png", import.meta.url).href, srcSet: `${new URL("assets/512/WpRBQtPsl40LtvHBOpsjGdjrM0.png", import.meta.url).href} 512w, ${new URL("assets/WpRBQtPsl40LtvHBOpsjGdjrM0.png", import.meta.url).href} 860w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jhY07x2nQ", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-bLcv5", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Link href={"https://sivi.ai?utm_campaign=founder-profile&utm_source=sona-design&utm_medium=website&utm_content=sona-building-sivi-home"} openInNewTab><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 434, intrinsicWidth: 507, pixelHeight: 434, pixelWidth: 507, ...toResponsiveImage_194x2gw(GNAGgtuGK)}} className={`${cx("framer-mz8a03", className)} framer-kqncgb`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jhY07x2nQ"} ref={ref} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition} variants={{"jhY07x2nQ-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}, EkLcw9KlX: {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} {...addPropertyOverrides({"jhY07x2nQ-hover": {"data-framer-name": undefined}, EkLcw9KlX: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-bLcv5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bLcv5 * { box-sizing: border-box; }", ".framer-bLcv5 .framer-kqncgb { display: block; }", ".framer-bLcv5 .framer-mz8a03 { height: 428px; overflow: hidden; position: relative; text-decoration: none; width: 500px; }", ".framer-bLcv5 .framer-v-mz8a03 .framer-mz8a03 { cursor: pointer; }", ".framer-bLcv5.framer-v-6ktg07 .framer-mz8a03, .framer-bLcv5.framer-v-mz8a03.hover .framer-mz8a03 { aspect-ratio: 1.1683389074693422 / 1; height: var(--framer-aspect-ratio-supported, 428px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 428
 * @framerIntrinsicWidth 500
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"EkLcw9KlX":{"layout":["fixed","fixed"]},"YW2LGBimd":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"GNAGgtuGK":"image"}
 */
const Framerl4gzF0vHu: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerl4gzF0vHu;

Framerl4gzF0vHu.displayName = "sivi-text-content-input";

Framerl4gzF0vHu.defaultProps = {height: 428, width: 500};

addPropertyControls(Framerl4gzF0vHu, {variant: {options: ["jhY07x2nQ", "EkLcw9KlX"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, GNAGgtuGK: {__defaultAssetReference: "data:framer/asset-reference,WpRBQtPsl40LtvHBOpsjGdjrM0.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerl4gzF0vHu, [])